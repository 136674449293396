.video-js .vjs-big-play-button {
  background-color: #fff;
  color: #2e9f92;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.video-js .vjs-play-progress {
  color: rgb(0, 0, 0);
}
.video-js .vjs-control-bar {
  color: rgb(0, 0, 0);
  background-color: #fff;
}
