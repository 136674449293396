body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
}
.ck.ck-editor__main .ck-editor__editable {
  height: 300px;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  height: 300px;
}
.ck.ck-editor__main > .ck-editor {
  height: 300px;
}
.PhoneInput--focus input {
  border: none;
  outline: none;
}
.PhoneInput input {
  border: none;
  outline: none;
  font-size: 16;
  font-family: "Poppins", sans-serif;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.MuiInput-underline:after,
.MuiInput-underline::before {
  border: none !important;
  display: none;
}
input {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  background-color: #fffaea;
  border-color: #ffe89d;
  color: #222f3e;
  display: none !important;
}
.tox .tox-statusbar a,
.tox .tox-statusbar__path-item,
.tox .tox-statusbar__wordcount {
  display: none !important;
}
