/* .slick-next {
  right: 0em;
  top: 35% !important;
}

.slick-prev {
  left: 0em;
  top: 35% !important;
}
.slick-arrow {
  top: 90%;
  border-radius: 50%;
  color: #fff;
  background-color: #2e9f92;
  position: absolute;
  z-index: 200;
  transform: scale(1.35);
}
.slick-arrow:hover {
  border-radius: 50%;
  color: #fff;
  background-color: #2e9f92;
  position: absolute;
  z-index: 200;
  opacity: 0.5;
}
.slick-arrow.slick-disabled {
  display: none;
  opacity: 0;
}
.slick-track {
  margin-left: 0;
} */
.react-multi-carousel-list {
  /* overflow: visible; */
}
.react-multiple-carousel__arrow {
  z-index: 800;
  background-color: #0290a1;
  /* overflow: visible; */
}
.react-multi-carousel-item {
  padding: 0 10px;
}

.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 0em;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 0em;
}
